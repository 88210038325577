<template>
  <div>
    <el-card class="box-card card-contanier">
      <div class="card-content"></div>
      <div class="card-content">
        <el-form :inline="true" @submit.native.prevent>
          <div style="float: left; width: 94%">
            <el-form-item label="模板名称：">
              <el-input v-model="tempName" placeholder="模板名称" size="medium" clearable>
              </el-input>
            </el-form-item>

            <el-form-item>
              <el-button @click="search" type="primary" icon="el-icon-search" size="small"
                >搜索</el-button
              >
            </el-form-item>

            <el-form-item>
              <el-button @click="refresh" icon="el-icon-refresh" size="medium"
                >重置</el-button
              >
            </el-form-item>
          </div>
          <el-form-item>
            <el-button type="primary" size="medium" @click="dialogVisible = true"
              >新增</el-button
            >
          </el-form-item>
          <!-- <el-form-item>
            <el-button type="primary" size="medium" @click="syncWxTempInfo"
              >同步模板</el-button
            >
          </el-form-item> -->
        </el-form>
      </div>
    </el-card>

    <el-table
      ref="multipleTable"
      :data="tableData"
      tooltip-effect="dark"
      style="width: 100%"
      size="mini"
      border
      stripe
    >
      <el-table-column prop="id" label="序号" align="center" width="60" fixed="left">
      </el-table-column>
      <el-table-column prop="tempName" label="模板名称" align="center" width="200">
      </el-table-column>

      <el-table-column prop="tempContent" label="模板内容" align="center" width="700">
      </el-table-column>

      <el-table-column prop="tempExample" label="模板示例" align="center" width="700">
      </el-table-column>

      <el-table-column
        prop="tempStat"
        label="模板状态"
        align="center"
        width="240"
        fixed="right"
      >
        <template slot-scope="scope">
          <el-switch
            style="display: block"
            v-model="scope.row.tempStat"
            :active-value="0"
            :inactive-value="1"
            active-color="#13ce66"
            inactive-color="#ff4949"
            active-text="启用"
            inactive-text="禁用"
            @change="changeStat($event, scope.row)"
          >
          </el-switch>
        </template>
      </el-table-column>

      <el-table-column prop="icon" label="操作" width="200" align="center" fixed="right">
        <template slot-scope="scope">
          <!-- <el-button @click="editHandler(scope.row.id)" type="text" size="small"
            >编辑</el-button
          >

          <el-divider direction="vertical"></el-divider> -->
          <el-popconfirm
            title="确认删除当前选中数据？"
            @confirm="delHandle(scope.row.id)"
          >
            <el-button type="text" slot="reference">删除</el-button>
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>
    <!---分页-->
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      layout="total, sizes, prev, pager, next, jumper"
      :page-sizes="[10, 20, 50, 100]"
      :current-page="current"
      :page-size="size"
      :total="total"
    >
    </el-pagination>

    <!--新增对话框  -->
    <el-dialog
      title="模板添加"
      :visible.sync="dialogVisible"
      width="45%"
      :before-close="handleClose"
      :close-on-click-modal="false"
    >
      <el-form
        :model="editForm"
        :rules="editFormRules"
        ref="editForm"
        label-width="200px"
        class="demo-editForm"
      >
        <el-form-item
          label="模板标题:"
          prop="tempName"
          label-width="120px"
          style="width: 95%"
        >
          <el-input
            v-model="editForm.tempName"
            auto-complete="off"
            placeholder="请填写模板标题"
          >
          </el-input>
        </el-form-item>

        <el-form-item
          label="模板ID:"
          prop="tempId"
          label-width="120px"
          style="width: 95%"
        >
          <el-input
            v-model="editForm.tempId"
            auto-complete="off"
            placeholder="请填写模板ID(微信中)"
          >
          </el-input>
        </el-form-item>

        <el-form-item
          label="模板一级行业:"
          prop="tempFirstIndustry"
          label-width="120px"
          style="width: 95%"
        >
          <el-input v-model="editForm.tempFirstIndustry" auto-complete="off"></el-input>
        </el-form-item>

        <el-form-item
          label="模板二级行业:"
          prop="wxAccount"
          label-width="120px"
          style="width: 95%"
        >
          <el-input v-model="editForm.tempSecondIndustry" auto-complete="off"></el-input>
        </el-form-item>

        <el-form-item
          label="模板内容:"
          prop="tempContent"
          label-width="120px"
          style="width: 95%"
        >
          <div
            style="
              width: 100%;
              display: flex;
              flex-direction: clounm;
              margin-bottom: 10px;
            "
            v-for="(item, index) in editForm.tempContent"
            :key="index"
          >
            <el-input
              v-model="item.field"
              auto-complete="off"
              style="width: 200px"
              placeholder="字段名(例:keword1)"
            >
            </el-input>
            <el-select v-model="item.stat" placeholder="请选择类型">
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
            <el-input
              v-model="item.title"
              auto-complete="off"
              style="width: 200px"
              placeholder="标题(例:活动名称)"
            >
            </el-input>
            <el-button
              icon="el-icon-delete"
              @click="delLine(item.randNum)"
              style="margin-left: 10px"
              :disabled="editForm.tempContent.length <= 1"
            ></el-button>
          </div>
          <el-button
            type="primary"
            icon="el-icon-plus"
            style="margin-top: 10px"
            @click="addLine"
            >添加关键词</el-button
          >
        </el-form-item>

        <el-form-item
          label="模板示例:"
          prop="tempExample"
          label-width="120px"
          style="width: 95%"
        >
          <el-input
            type="textarea"
            :autosize="{ minRows: 4, maxRows: 10 }"
            v-model="editForm.tempExample"
            auto-complete="off"
          >
          </el-input>
        </el-form-item>

        <el-form-item
          label="备注:"
          prop="tempNote"
          label-width="120px"
          style="width: 95%"
        >
          <el-input
            type="textarea"
            :autosize="{ minRows: 4, maxRows: 10 }"
            v-model="editForm.tempNote"
            auto-complete="off"
          >
          </el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer" style="width: 95%">
        <el-button type="primary" @click="submitForm('editForm')">保 存</el-button>
        <el-button @click="handleClose">关 闭</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import api from "../../api/user";
import temp from "../../api/temp";

import { getRandomString } from "../../utils/rand";
export default {
  name: "tempconf",
  data() {
    return {
      ranks: "",
      dialogVisible: false,
      editForm: {
        tempContent: [{ randNum: getRandomString(5), field: "", stat: "", title: "" }],
      },
      editFormRules: {
        tempName: [{ required: true, message: "请填写模板标题", trigger: "blur" }],
        tempId: [{ required: true, message: "请填写模板ID(微信中)", trigger: "blur" }],
      },
      options: [
        {
          value: "string",
          label: "字符串",
        },
        {
          value: "datetime",
          label: "时间",
        },
        // {
        //   value: "number",
        //   label: "数值",
        // },
      ],
      total: 0,
      size: 10,
      current: 1,

      tempName: "",

      tableData: [],
    };
  },
  created() {
    // this.getUserInfo();
    this.getTempList();
  },
  methods: {
    async getUserInfo() {
      await api.userinfo().then((res) => {
        this.ranks = res.data.data.result.ranks;
        if (res.data.data.result.ranks === 1) {
          this.flag = true;
        }
      });
    },
    getTempList() {
      temp.getTempListData(this.tempName).then((res) => {
        this.tableData = res.data.data.result.records;
        this.total = res.data.data.result.total;
        this.size = res.data.data.result.size;
        this.current = res.data.data.result.current;
      });
    },
    refresh() {
      this.tempName = "";
      this.current = 1;
    },
    search() {
      this.current = 1;
    },
    handleSizeChange(val) {
      this.size = val;
    },
    handleCurrentChange(val) {
      this.current = val;
    },
    handleClose() {
      this.dialogVisible = false;
      this.editForm = {
        tempContent: [{ randNum: "", field: "", stat: "", title: "" }],
      };
    },
    //添加一行
    addLine() {
      let one = { randNum: getRandomString(5), field: "", stat: "", title: "" };
      this.editForm.tempContent.push(one);
    },
    //删除一行
    delLine(val) {
      this.editForm.tempContent.splice(
        this.editForm.tempContent.findIndex((e) => e.randNum === val),
        1
      );
    },
    //todo 同步微信模板消息
    syncWxTempInfo() {
      this.$confirm("是否同步微信消息模板？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          temp.syncWxTempInfo().then((res) => {
            // this.$notify({
            //   title: "prefect",
            //   message: "同步成功",
            //   position: "bottom-right",
            //   type: "success",
            // });
          });
        })
        .catch(() => {});
    },
    //删除模板
    delHandle(tempId) {
      temp.delete(tempId).then((res) => {
        this.$notify({
          title: "prefect",
          message: "删除成功",
          position: "bottom-right",
          type: "success",
          onClose: () => {
            this.getTempList();
          },
        });
      });
    },
    //启用禁用
    changeStat(e, val) {
      temp.changeStat(e, val.id).then((res) => {
        this.$notify({
          title: "prefect",
          message: "启用/禁用成功",
          position: "bottom-right",
          type: "success",
          onClose: () => {
            this.getTempList();
          },
        });
      });
    },
    //提交保存
    submitForm(formName) {
      console.log(this.editForm);
      this.$refs[formName].validate((valid) => {
        if (valid) {
          // let status = this.editForm.wxId ? "update" : "save";
          temp.saveTemp(this.editForm).then((res) => {
            this.$notify({
              title: "prefect",
              message: "操作成功",
              position: "bottom-right",
              type: "success",
              onClose: () => {
                this.getTempList();
              },
            });
            this.handleClose();
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
  },
};
</script>

<style scoped>
.card-contanier {
  margin-bottom: 5px;
}
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 100px;
  height: 100px;
  line-height: 100px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
.map {
  width: 80%;
  height: 300px;
}
.el-pagination {
  float: right;
  margin-top: 22px;
}
</style>
