import { postRequest, getRequest, deleteRequest } from '../utils/request'
export default {
    getTempListData(tempName){
        return getRequest(`/api/systemp/list?tempName=${tempName}`)
    },
    /**
     * 添加模板
     * @param {*} params 
     * @returns 
     */
    saveTemp(params){
        return postRequest(`/api/systemp/save`,params)
    },
    delete(id){
        return postRequest(`/api/systemp/delete?id=${id}`)
    },
    getTempConfigTempId(confTempId){
        return getRequest(`/api/systemp/getTempConfigTempId?confTempId=${confTempId}`)
    },

    /**
     * 获得一个启用的模板
     */
    getOneTemp(){
        return getRequest(`/api/systemp/getOneTemp`)
    },

    /**
     * 同步微信模板
     */
    syncWxTempInfo(){
        return getRequest(`/api/systemp/syncWxTempInfo`)
    },
    /**
     * 
     */
    changeStat(stat,id){
        return getRequest(`/api/systemp/changeStat?stat=${stat}&id=${id}`)
    },
    //发送消息
    tempMsg(data){
        return postRequest(`/api/test/tempMsg`,data)
    },
    tempMember(data){
        return postRequest(`/api/test/tempMember`,data)
    },
   
}